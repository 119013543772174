
export const textComfirm = (content) => {
  return [
    {
      label: '还款类型：',
      prop: 'repayType',
      value: ''
    },
    {
      label: '还款主体：',
      prop: 'repaySubject',
      value: ''
    },
    {
      label: '还款时间：',
      prop: 'preRepayDate',
      value: ''
    },
    {
      label: '实收日期：',
      prop: 'actualRepayDate',
      value: ''
    },
    {
      label: '还款期数：',
      prop: 'repayPeriods',
      value: ''
    },
    {
      label: '实收金额(元)：',
      prop: 'actualRepayAmount',
      value: ''
    }
  ]
}
