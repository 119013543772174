<template>
    <div class="box">
      <div class="accountabel">
        <div class="title-top">
          <div class="msg">
            <p><i class="el-icon-warning" style="font-size: 50px;color: #2862E7"></i></p>
            <p>请再次确认以下还款信息是否正确,</p>
            <p style="color: #EE1C1C;">一旦提交无法修改！</p>
          </div>
        </div>

        <!--        账号-->
        <!-- <div  v-for="item of 2" :key="item">
          <repayment-account
            :bankAccount="bankAccountInfo"
            :Index="item">
          </repayment-account>
        </div> -->

        <!--        文本-->

        <div class="left">
          <div class="content" style="background-color: transparent">
            <el-row :gutter="20">
              <el-col :span="8" v-for="item of List" :key="item.prop" style="padding: 10px">
                <div style="display: flex;flex-direction: row;align-items: center">
                  <div class="redbox"></div>
                  <div :style="{color:['actualRepayDate','actualRepayAmount'].includes(item.prop) ? '#EE1C1C':''}">
                    {{ item.label }}{{ item.value }}
                  </div>
                </div>
              </el-col>
            </el-row>

          </div>
        </div>

      </div>
    </div>
</template>

<script>
// import RepaymentAccount from './repayment-account'
import { textComfirm } from '../utils/repayment-comfirm.config'
import { formatDate } from '@/utils/auth/common'

export default {
  name: 'RepaymentComfirm',
  data () {
    return {
      List: []
    }
  },
  components: {
    // RepaymentAccount
  },
  props: {
    // 账户
    bankAccountInfo: {
      type: Array,
      default: () => []
    },
    // 展示文本
    queryParas: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    textComfirm () {
      return textComfirm(this)
    }
  },
  mounted () {
    this.fomTextComfirm()
  },
  methods: {
    /**
     * 展示文本
     */
    fomTextComfirm () {
      this.List = this.textComfirm
      const queryParas = this.queryParas
      this.List = this.List.map(item => {
        for (const [key, value] of Object.entries(queryParas)) {
          if (item.prop === key) {
            switch (item.prop) {
              case 'repayType':
                item.value = parseInt(value) === 0 ? '本金' : '服务费'
                break
              case 'actualRepayDate':
                item.value = formatDate(value, 'YY-MM-DD')
                break
              default:
                item.value = value
            }
          }
        }
        return item
      })
    }
  }
}
</script>

<style scoped lang="scss">
.accountabel {
  width: 100%;

  .content {
    background: #f9f9f6;
    padding: 14px 0 0 10px;
    width: 100%;
    margin-top: 11px;

    .contentitem {
      font-size: 14px;
      font-weight: 400;
      color: #38373a;
      display: flex;
      align-items: center;
      padding-bottom: 18px;
    }
  }

  .left {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .newtitle {
    display: flex;
    align-items: center;

    > div:nth-child(1) {
      width: 150px;
      height: 4px;
      background: linear-gradient(90deg, #ffffff,  #6996FF);
    }

    > div:nth-child(2) {
      font-size: 16px;
      font-weight: bold;
      color: #38373a;
      padding: 0 10px;
    }

    > div:nth-child(3) {
      width: 150px;
      height: 4px;
      background: linear-gradient(90deg, #6996FF,  #ffffff);
    }
  }
}

.box {
  color: #38373A;
  font-size: 14px;

  .title {
    text-align: center;
  }

  .right {
    width: 1000px;
    border: 0.5px solid #333333;
    padding: 10px;

    .label {
      display: inline-block;
      width: 400px;
      padding: 0 15px;
    }
  }
}

.msg {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #38373A;
  text-align: center
}
</style>
